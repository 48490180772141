<template>
  <div id="app">
    <Navbar />
    <First  @formSubmittedApp="openModal"/>
    <Second />
    <Process />
    <Assistanse />
    <Advantages />
    <Team />
    <Testimonials />
    <Contacts />
    <Footer />
    <ChatIcon @formSubmittedApp="openModal"/>
   
    <!-- Модальне вікно підтвердження -->
        <ModalComponent 
      v-if="showModal" 
      :showModal="showModal" 
      @close="closeModal"
    />

  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import First from './components/First.vue';
import Second from './components/Second.vue';
import Process from './components/Process.vue';
import Assistanse from './components/Assistanse.vue';
import Advantages from './components/Advantages.vue';
import Team from './components/Team.vue';
import Testimonials from './components/Testimonials.vue';
import Contacts from './components/Contacts.vue';
import ChatIcon from '@/components/ChatIcon.vue';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
  components: {
    Navbar,
    First,
    Second,
    Process,
    Assistanse,
    Advantages,
    Team,
    Testimonials,
    Contacts,
    Footer,
    ChatIcon,
    ModalComponent,
  },
  data() {
    return {
      showModal: false,
      userName: '',
    };
  },
  methods: {
    openModal() {
      console.log('Собачий хуй')
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  z-index: 1;
}
</style>