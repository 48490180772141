<template>
  <header>
    <nav class="navbar">
      <div class="logo">
        <router-link to="/"> 
           <img :src="Logo" :alt="Logo" class="logo" />
        </router-link>
      </div>
      <ul :class="['nav-links', { 'nav-active': isMenuOpen }]">
        <li><a @click="scrollToSection('how-we-work')">Как мы работаем</a></li>
        <li><a @click="scrollToSection('process')">Процесс возврата</a></li>
        <li><a @click="scrollToSection('type_of_scams')">Типы проблем</a></li>
        <li><a @click="scrollToSection('advantages')">Наши преимущества</a></li>
        <li><a @click="scrollToSection('team')">Наша команда</a></li>
        <li><a @click="scrollToSection('reviews')">Отзывы</a></li>
        <li><a @click="scrollToSection('contact_us')">Контакты</a></li>

      </ul>
      <div class="burger" @click="toggleMenu">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </nav>
  </header>
  </template>
  
  <script>
  import Logo from "@/assets/img/logo2.png";
  export default {
    name:"NavbarComponent",
    data() {
      return {
        isMenuOpen: false,
        Logo: Logo,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      closeMenu(){
        this.isMenuOpen = !this.isMenuOpen;
      }, 
      scrollToSection(sectionId) {
    const element = document.getElementById(sectionId);
    if (element) {
      const offsetTop = element.offsetTop - 100; // Відступ на 30px вище
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
      this.closeMenu(); // Закриває меню після натискання
    }
  }
    }
  };
  </script>
  
<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color:; Другий колір */
  color:  var(--text-color);
  padding: 1rem 2rem;
}

.navbar .logo a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
}


.nav-links li {
  /* margin-left: 2rem; */
}

.nav-links a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1rem;
  padding: 5px;
  margin-left: 10px;
}
.nav-links a:hover{
  background: var( --accent-color);
  border-radius: 50px;
 
}
.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
  transition: all 0.3s ease;
}
.logo {
  width: 200px;
  height: auto;
}

@media screen and (max-width: 1130px) {
  .nav-links {
    flex-wrap: wrap;
  }
  .nav-links a {
    text-wrap: nowrap;
  }
}
/* Адаптивність */
@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .burger {
    display: block;
    background: var(--text-color);
    border-radius: 50px;
    padding: 10px;
  }

  .nav-active {
    display: flex;
    background-color:#e0f0f9;
    position: absolute;
    top: 95px;
    right: 0;
    width: 100%;
    z-index: 999;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 1rem 0;
  }
  .nav-links li a{
    font-size: 1.5rem;
    font-weight: 400;
  }
  .nav-links a:hover{
  background: none;
  border-radius: 50px;
}
}
  </style>
  