<template>
    <section id="process" class="process-section _container">
      <div class="header-content">
        <h2>Процесс возврата</h2>
      </div>
      <div class="dropdowns-container">
        <div v-for="(step, index) in processSteps" :key="index" class="dropdown">
          <div class="dropdown-header" @click="toggleDropdown(index)">
            <h3>{{ step.title }}</h3>
            <span class="arrow" :class="{ 'up': activeIndex === index }">▼</span>
          </div>
          <transition name="fade">
            <div v-show="activeIndex === index" class="dropdown-content">
              <p>{{ step.description }}</p>
            </div>
          </transition>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ProcessSection',
    data() {
      return {
        activeIndex: null,
        processSteps: [
          {
            title: 'Анализ материалов',
            description: 'Чтобы ускорить процесс, соберите в одном месте все необходимые документы: подписанные договоры, скриншоты переписок, выписки оплаты и другие доказательства.',
          },
          {
            title: 'Разработка стратегии',
            description: 'Обратившись в нашу компанию, вы можете быть уверены, что вашу проблему будет решать персональная команда опытных юристов, имеющих успешный опыт возврата средств. Мы прежде всего сосредотачиваемся на досудебных методах возврата, так как они являются наиболее быстрыми и эффективными.',
          },
          {
            title: 'Подготовка документов',
            description: 'Мы всегда заключаем двусторонний договор на оказание услуг и представление интересов клиента во всех правовых органах и инстанциях. Прежде всего, мы сосредотачиваемся на досудебных методах возврата, так как они являются наиболее быстрыми и эффективными. Если возникнет необходимость, мы также защитим ваши интересы в суде. Мы не беремся за дело, если не уверены в возможности возврата средств.',
          },
          {
            title: 'Ведение дела до результата',
            description: 'Юристы нашей компании представляют интересы клиента на протяжении всей процедуры оспаривания транзакции. Мы выполняем всю необходимую работу: начиная с сбора документации в соответствующих органах и заканчивая ответами на запросы от банков и платежных систем. Назначенный юрист всегда на связи, он отвечает на ваши вопросы, информирует о ходе дела и предоставляет отчёты о промежуточных этапах.',
          },
          {
            title: 'Возвращение средств',
            description: 'Благодаря совместной и слаженной работе наших специалистов, мы достигаем положительных результатов, и клиент получает возврат средств на свой личный счёт. Оплата за предоставленные юридические услуги осуществляется только после успешного завершения процесса возврата. Мы применяем исключительно законные методы и в 90% случаев достигаем возврата в досудебном порядке.',
          },
        ],
      };
    },
    methods: {
      toggleDropdown(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      },
    },
  };
  </script>
  
  <style scoped>
  .process-section {
    padding: 2rem;
  }
  
  .header-content {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header-content h2 {
    font-size: 2.5rem;
  }
  
  .dropdowns-container {
    margin-bottom: 2rem;
  }
  
  .dropdown {
    margin-bottom: 1rem;
  }
  
  .dropdown-header {
    cursor: pointer;
    background-color: var( --text-color);
    color: #fff; /* Колір тексту заголовка */
    padding: 1rem;
    border-radius: 5px; /* Закруглені кути */
    display: flex;
    justify-content: space-between; /* Вирівнювання тексту та стрілки */
    align-items: center;
    transition: background-color 0.3s ease; /* Анімація при наведенні */
  }
  .dropdown-header h3 {
    color: rgb(241, 247, 251); 
  }
  .dropdown-header:hover {
    background-color: var(--accent-color);
  }
  
  .dropdown-content {
    padding: 1rem;
    border: 1px solid  var( --text-color); /* Рамка для контенту */
    border-radius: 5px; /* Закруглені кути */
    background-color: #f9f9f9; /* Світлий фон для контенту */
  }
  
  .arrow {
    transition: transform 0.3s ease; /* Анімація для стрілки */
  }
  
  .arrow.up {
    transform: rotate(180deg); /* Поворот стрілки при відкритті */
  }
  
  /* Анімація для контенту */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  @media screen and (max-width:768px) {
    .process-section {
      padding: 0.5rem;
    }
  }
  </style>
  
