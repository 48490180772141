<template>
  <div>
    <div class="chat-icon-wrapper" @click="toggleChatModal">
      <div class="chat-icon">
        <img 
          v-if="!showChatModal" 
          :src="chatIcon" 
          alt="Chat Icon" 
          width="40" 
          height="40"
        />
        <img 
          v-else 
          :src="closeIcon" 
          alt="Close Icon" 
          width="40" 
          height="40"
        />
      </div>
    </div>

    <!-- Модальне вікно з формою -->
    <div v-if="showChatModal" class="modal" @click.self="toggleChatModal">
      <div class="modal-content">
        <ContactForm @formSubmitted="handleFormSubmitted" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
  data() {
    return {
      showChatModal: false, // Змінили назву поля для уникнення конфлікту
    };
  },
  computed: {
    chatIcon() {
      return require('@/assets/icons/chat.svg');
    },
    closeIcon() {
      return require('@/assets/icons/close.svg');
    },
  },
  methods: {
    toggleChatModal() {
      this.showChatModal = !this.showChatModal;
    },
    handleFormSubmitted() {
      this.showChatModal = false; // Закриває модалку після успішної відправки
      this.$emit('formSubmittedApp');
    },
  },
};
</script>

<style scoped>
.chat-icon-wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.chat-icon {
  width: 60px;
  height: 60px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: pulse 2s infinite; /* Додаємо анімацію пульсації */
}

.chat-icon:hover {
  transform: scale(1.1); /* Можеш залишити цей ефект на ховер, якщо хочеш */
}


.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
}
</style>
