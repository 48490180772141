<template>
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay" @click="close">
        <div class="modal-content" @click.stop>
          <h3>Уважаемый(ая) {{ userName }}!</h3>
          <p>Спасибо за оставленную заявку. В ближайшее время с вами свяжется юрист компании AB Legal Group.</p>
          <p>На вашу почту было отправлено письмо. Если у вас возникли вопросы, вы можете связаться с нами через наш Телеграм или ответить на письмо.</p>
          <button class="cta" @click="close">Закрыть</button>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      showModal: Boolean,
      userName: String,
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 90%;
  }
  
  button {
    margin-top: 15px;
  }
  </style>
  