<template>
    <section class="contact_us _container" id="contact_us">
        <div class="container_contacts _container">
            <div class="contact-info">
                <h2>Наши Контакты</h2>
                <p>Адрес: Av. du Boulevard 21/1st floor, 1210 Bruxelles, Belgium</p>
                <p>Email: <a href="mailto:info@ablegalgroups.com">info@ablegalgroups.com</a></p>
                <div class="office-hours">
                    <h3>Office Hours</h3>
                    <p>Monday to Friday 9:00 am to 6:00 pm</p>
                    <p>Saturday 9:00 am to 12 noon</p>
                    <p>Closed on Sundays</p>
                </div>
                <div class="form_button">
                  <a @click="scrollToSection('Form')" type="button">Оставить заявку</a>
                </div>

            </div>
            <div class="map-container">
                <iframe src="https://www.google.com/maps?q=Av.+du+Boulevard+21%2F1st+floor%2C+1210+Bruxelles%2C+Belgium&amp;output=embed" width="600" height="450" allowfullscreen="" loading="lazy" style="border: 0px;">
                    
                </iframe>
            </div>

        </div>
        <div class="contact_form">
        <!-- <ContactForm /> -->
      </div>
    
    </section>
  </template>
  
<script>
// import ContactForm from '@/components/ContactForm.vue';
export default {
  name: 'ContactUsComponent',
  components: {
    // ContactForm
  },
  methods: {
  scrollToSection(sectionId) {
    const element = document.getElementById(sectionId);
    if (element) {
      const offsetTop = element.offsetTop - 120; // Відступ на 30px вище
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth' // Плавний скрол
      });
    }
  }
}

}
</script>
<style scoped>

.contact-us {
  padding: 2rem 0;
}
.container_contacts{ 
    display: flex;
}
.contact-info {
  flex: 1;
  padding-right: 20px; /* Між елементами */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.contact-info p {
    padding: 10px 0;
}
.map-container {
  flex: 1;
}
.contact-form {
  margin-top: 20px;
}
.form_button {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.form_button a {
  font-size: 2rem;
  width: 70%;
  text-align: center;
  border: 2px solid var(--text-color);
  color: var(--text-color);
  padding: 10px;
}
.form_button a:hover {
  background-color:var(--text-color);;
  color:white;  
}
@media screen and (max-width: 768px) {
  .contact-us {
    display: block;
  }
  .container_contacts{ 
    flex-direction: column;
    justify-content: center;
  }
  .map-container {
    display: flex;
    justify-content: center;
  }
  .map-container iframe {
    max-width: 350px;
  }
  .carousel__next {
    right: inherit;
  }
  .form_button a{
    margin: 0;
    width: 100%;
    font-size: 1.5rem;
    text-wrap: nowrap;
  }
}
</style>
