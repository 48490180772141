<template>
    <section  class="types-of-scams scams" id="type_of_scams">
      <div class="container _container">
        <div class="section-title">
          <h2>В каких случаях необходимо обратиться за помощью </h2>
        </div>
        <carousel
          class="carousel scams-container"
          :items-to-show="3"
          :items-to-scroll="1"
          :breakpoints="breakpoints"
          navigation
        >
          <slide v-for="(scam, index) in scams" :key="index">
            <div class="scam">
              <div href="#" class="slick-slide slick-active">
                <div class="slide_icon">         
                    <div class="ic">
                        <img :src="scam.icon" :alt="scam.title" />
                    </div>
                  <div class="titl">     <h3>{{ scam.title }}</h3></div>
                
                </div>
                <div class="scam-text">{{ scam.description }}</div>
              </div>
            </div>
          </slide>
          
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </section>
  </template>
  
  <script>

import CryptoIcon from '@/assets/icons/crypto.svg';
import BrokerIcon from '@/assets/icons/broker.svg';
import CardIcon from '@/assets/icons/card.svg';
import CasinoIcon from '@/assets/icons/casino.svg';
import VisaIcon from '@/assets/icons/visa.svg';
import TransferIcon from '@/assets/icons/transfer.svg';

  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
  // import axios from 'axios';
  
  export default {
    name: 'AssistatseComponent',
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    data() {
      return {
        breakpoints: {
          1200: {
            itemsToShow: 3,
            itemsToScroll: 1,
          },
          576: {
            itemsToShow: 2,
            itemsToScroll: 1,
          },
          320: {
            itemsToShow: 1,
            itemsToScroll: 1,
          },
        },
  
        scams: [
        {
          title: 'Возврат криптовалюты',
          description: 'Мы обладаем возможностью отслеживать финансовые и криптовалютные транзакции благодаря нашему значительному опыту в юридической сфере и установленным связям с ведущими финансовыми организациями.',
          icon: CryptoIcon,
        },
        {
          title: 'Брокеры и инвестиционные проекты',
          description: 'Если вы столкнулись с проблемами при работе с брокерами или инвестиционными проектами, мы готовы помочь вам.',
          icon: BrokerIcon,
        },
        {
          title: 'Банковские карты физических лиц',
          description: 'Если вы переводили деньги на банковские карты физических лиц через прямой перевод или обменники, мы поможем вам вернуть ваши средства.',
          icon: CardIcon,
        },
        {
          title: 'Казино',
          description: 'Если вы столкнулись с проблемами при игре в казино, мы можем помочь вам вернуть ваши деньги.',
          icon: CasinoIcon,
        },
        {
          title: 'VISA/MasterCard/Maestro/МИР',
          description: 'Если вы переводили деньги на счета компаний с помощью банковской карты, мы поможем вам вернуть ваши средства.',
          icon: VisaIcon,
        },
        {
          title: 'SWIFT/WIRE/SEPA Transfers',
          description: 'Если вы переводили деньги на счета юридических или физических лиц с помощью банковских переводов, мы поможем вам вернуть ваши средства.',
          icon: TransferIcon,
        },
        ],
      };
    },
  };
  </script>
  
<style scoped>
.section-title h2{ 
    text-align: center;
    font-size: 2rem;
}
.carousel-slide {
    align-items: flex-start;
}
.slick-slide {
    padding: 40px;
}
.slide_icon {
    gap: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.slide_icon img{
    width: 60px;
    height: 60px;
}
.ic{ 
    flex: 0.5 ;
}
.titl {
    flex: 1.2 ;
}
.scams {
    margin-top: 60px;
}

@media screen and (max-width: 768px) {
    .scams {
        margin-top: 30px;
    }
}

</style>