<template>
  <section id="reviews" class="types-of-scams scams back">
    <div class="container">
      <div class="_container">
        <div class="section-title">
          <h2>
            Отзывы 
          </h2>
        </div>
        <carousel
          class="carousel scams-container"
          :items-to-show="4"
          :items-to-scroll="1"
          :breakpoints="breakpoints"
          navigation
        >
          <slide v-for="(testimonial, index) in testimonials" :key="index">
            <div class="ts-body">
              <div class="ts-inner">
                <div class="ts-stars">
                  <img :src="stars_img" alt="Rating stars">
                </div>
                <div class="ts-date">
                  {{ testimonial.date }}
                </div>
              </div>
              <div class="ts-comment">
                <div class="photo_name">
                  <img :src="testimonial.photo" alt="Client photo" class="ts-photo" />
                  <div class="ts-name">{{ testimonial.name }}</div>
                </div>
                <div class="ts-title">{{ testimonial.title }}</div>
                <div class="ts-text">{{ testimonial.text }}</div>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>



// import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import Stars from "@/assets/img/5stars.png";
import People1 from "@/assets//img/people/photo1.jpg";
import People2 from "@/assets//img/people/photo2.jpg";
import People3 from "@/assets//img/people/photo4.jpg";
import People4 from "@/assets//img/people/photo3.jpg";
export default {
  name: 'ReviewsComponent',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      stars_img: Stars,
      testimonials: [],
      breakpoints: {
        1200: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        992: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        768: {
          itemsToShow: 2,
          itemsToScroll: 1,
        },
        576: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
        320: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
      },
    };
  },

  methods: {
    fetchTestimonials() {
      // Замість запиту використовуємо статичні дані
      this.testimonials = [
        {
          id: 7,
          name: "Анатолий Куренков ",
          photo: People1,
          text: "В ноябре я обратилась к юристам ablegal group, предоставила всю информацию о брокере, и работа была выполнена быстро. После нескольких обращений брокер перевел деньги на мой кошелек, и я смогла вывести свои средства обратно. Я уже начала думать, что прощаюсь с деньгами, но удалось вернуть средства от этих мошенников!",
          date: "2024-05-07",
        },
        {
          id: 8,
          name: "Лола Дюран",
          photo: People2,
          text: "Меня обманули в CBM Group,  к сожалению, я передала все свои деньги мошенникам, и была на дне в отчаянии, я сама с Марселя. Но справедливость восторжествовала, когда я обратилась в компанию  ABLegal Group. Они помогли мне вернуть средства и возбудить уголовное дело! Большое спасибо им — они выполнили всю работу честно и быстро! Безмерно благодарна за работу!!!!",
          date: "2024-06-08",
        },
        {
          id: 9,
          name: "Евгений Попов",
          photo: People3,
          text: "Я обратился в  ABLegal Group в отчаянии после потери средств у мошенников, влез в кредит. Честно говоря, не ожидал, что процесс возврата будет таким быстрым! Всего через несколько обращений мои деньги вернулись ко мне. Большое спасибо за оперативность и поддержку!",
          date: "2024-09-29",
        },
        {
          id: 10,
          name: "Mussa  Abdusharipov",
          photo: People4,
          text: "Я искал юридическую помощь после обмана брокером и нашол  ABLegal Group. С ними я получил не только квалифицированную помощь, но и моральную поддержку. Искренне благодарен. Каждый этап процесса был четко объяснен, и я знала, что могу им доверять. Вернула свои деньги, и это невероятно!",
          date: "2024-05-10",
        },
      ];
      console.log('Static testimonials data:', this.testimonials);
    },
  },
  mounted() {
    this.fetchTestimonials(); // Викликаємо метод для ініціалізації даних
  },
};
</script>

<style scoped>
.container{
  background-color: var(--text-color);
}
.section-title {
  margin-bottom: 40px;
}
.section-title h2{ 
  color: white;
  font-size: 2.5rem;
  text-align: center;
}
.ts-body {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    height: 100%;
    margin: 0 10px;
}
.ts-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ts-date {
    font-size: .875rem;
    color: #696a6a;
}
.ts-comment {
    margin-top: 16px;
}
.photo_name {
    display: flex;
    align-items: center;
    gap: 16px;
}
.ts-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}
.ts-name {
    font-size: 1rem;
    font-weight: 600;
}
.ts-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 8px;
}
.ts-text {
    font-size: 0.7rem;
    color: #333;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}
.ts-stars img {
  height: 20px;
}
@media screen and (max-width: 768px) {
  .ts-title{ 
    font-size: 1.5rem;
  }
  .ts-text {
    font-size: 1rem;
  }
  .second-title {
    font-size: 2.3rem;
  }
}
</style>