<template>
  <div class="contact-form" id="Form">
    <div v-if="isLoading" class="overlay">
      <div class="spinner"></div>
    </div>
    <h3>{{ title }}</h3>
    <form @submit.prevent="submitForm">
      <!-- Поле Ім'я -->
      <div class="form-group">
        <label for="name">Имя</label>
        <input 
          type="text" 
          id="name" 
          v-model="form.name" 
          @blur="validateName" 
          :class="{ 'is-invalid': errors.name }"
          required 
        />
        <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
      </div>

      <!-- Поле Email -->
      <div class="form-group">
        <label for="email">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="form.email" 
          @blur="validateEmail" 
          :class="{ 'is-invalid': errors.email }"
          required 
        />
        <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      </div>

      <!-- Поле Телефон -->
      <div class="form-group">
        <label for="phone">Номер телефона</label>
        <input 
          type="tel" 
          id="phone" 
          ref="phoneInput"
          v-model="form.phone" 
          @blur="validatePhone" 
          :class="{ 'is-invalid': errors.phone }"
          required 
        />
        <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
      </div>

      <!-- Поле Повідомлення -->
      <div class="form-group">
        <label for="case">Краткое описание проблемы</label>
        <textarea 
          id="case" 
          v-model="form.case" 
          @blur="validateCase" 
          :class="{ 'is-invalid': errors.case }"
          rows="4" 
          required
        ></textarea>
        <div v-if="errors.case" class="error-message">{{ errors.case }}</div>
      </div>

      <!-- Кнопка Відправки -->
      <div class="tab-button">
        <button type="submit" class="cta">Отправить</button>
      </div>

      <!-- Повідомлення про помилку -->
      <div v-if="errorMessage" class="notification error">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        case: "",
      },
      iti: null,
      errors: {},
      successMessage: "",
      errorMessage: "",
      isLoading: false, // Додаємо стан завантаження
    };
  },
  mounted() {
    const input = this.$refs.phoneInput;
    if (input) {
      this.iti = intlTelInput(input, {
        separateDialCode: true,
        initialCountry: "auto",
        geoIpLookup: this.lookupIPLocation,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js',
      });
    }
  },
  methods: {
    lookupIPLocation(callback) {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const countryCode = data.country_code;
          callback(countryCode);
        })
        .catch(() => {
          callback('us');
        });
    },
    validateName() {
      this.errors.name = this.form.name ? '' : 'Введите имя';
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors.email = emailRegex.test(this.form.email) ? '' : 'Некоректный адрес';
    },
    validatePhone() {
      const phoneNumber = this.form.phone;
      if (!phoneNumber || phoneNumber.length < 7) {
        this.errors.phone = 'Неверный номер телефона';
      } else {
        this.errors.phone = '';
      }
    },
    validateCase() {
      this.errors.case = this.form.case ? '' : 'Сообщение не отправлено';
    },
    submitForm() {
  this.validateName();
  this.validateEmail();
  this.validatePhone();
  this.validateCase();

  if (Object.values(this.errors).every(error => !error)) {
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const fullPhoneNumber = `+${countryCode} ${this.form.phone}`;

    const firstEmailParams = {
      to_name: this.form.name,
      email: this.form.email,
      phone: fullPhoneNumber,
      message: this.form.case,
    };

    // Показуємо спінер і затемнення фону
    this.isLoading = true;

    axios.post('https://api.ablegalgroups.com/api/send-email/', firstEmailParams, {
      headers: {
        'Template-ID': 'template_ulidzha',  
        'Content-Type': 'application/json',
      }
    })
      .then(() => {  // Видаляємо змінну 'response', якщо не використовується
        const secondEmailParams = {
          to_name: this.form.name,
          email: this.form.email,
          subject: 'Спасибо за заявку',
          greeting: 'Уважаемый(ая)',
          body_text: 'В ближайшее время с вами свяжется юрист компании AB Legal Group для дальнейшего обсуждения.',
          body_text1: 'В случае возникновения вопросов вы можете связаться с нами через наш Телеграм-канал либо ответить на данное письмо.',  
          signature: 'С уважением, AB Legal Group',
          company_name: 'AB Legal Group',
          text_tg: 'Наш телеграм канал AB Legal Group',
          contact_tg: "https://t.me/law_company_01",
        };

        return axios.post('https://api.ablegalgroups.com/api/send-email/', secondEmailParams, {
          headers: {
            'Template-ID': 'template_tl3iowa',
            'Content-Type': 'application/json',
          }
        });
      })
      .then(() => {  // Видаляємо змінну 'response', якщо не використовується
        this.isLoading = false;  // Ховаємо спінер після завершення
        this.form = { name: '', phone: '', email: '', case: '' };
        this.$emit('formSubmitted', firstEmailParams.to_name);
      })
      .catch(() => {  // Видаляємо змінну 'error', якщо не використовується
        this.isLoading = false;  // Ховаємо спінер у випадку помилки
        this.errorMessage = 'Произошла ошибка при отправке. Попробуйте еще раз.';
      });
  } else {
    this.errorMessage = 'Исправьте ошибки в форме перед отправкой.';
  }
}

  }
};
</script>

<style scoped>
/* Стилі для спінера та затемнення фону */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #007bff;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cta {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: pulse 2s infinite;
}

.cta:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}



.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading-form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input.is-invalid, textarea.is-invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.success-message {
  color: #28a745;
  font-size: 1rem;
  margin-top: 1rem;
}

.tab-button {
  margin-top: 1rem;

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Трохи збільшуємо кнопку */
  }
  100% {
    transform: scale(1);
  }
}

.cta {
  background-color: var(--text-color);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: pulse 2s infinite; /* Додаємо анімацію пульсації */
}

.cta:hover {
  background-color: var(--accent-color);
  transform: scale(1.1); /* Збільшуємо кнопку при ховері */
}

.payment-icons {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.payment-icons img {
  width: auto;
  max-width: 100px;
}

@media (max-width: 768px) {
  .payment-icons {
    justify-content: center;
    margin: 0 50px;
  }

  .payment-icons img {
    max-width: 80px;
  }
  .heading-form {
    padding:0;
  }
  .form-group {
  margin-bottom: 1rem;
  }

  .form-group input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .cta {
    font-size: 1.5rem;
    width: 100%;
    border-radius: 6px;
  }
}
</style>
