<template>
    <section id="advantages" class="advantages">
        <h2>Наши преимущества</h2>
        <div class="advantages__container _container">
            <div class="advantage__card">
                <!-- <img src="path/to/image1.jpg" alt="Конфиденциальность" class="advantage__image"> -->
                <h3>Конфиденциальность</h3>
                <p>При подписании договора с нами вы получаете защиту в соответствии с законом о адвокатской тайне. Все данные, полученные в процессе возврата ваших средств, остаются абсолютно конфиденциальными и надежно защищены от раскрытия при любых обстоятельствах.</p>
            </div>
            <div class="advantage__card">
                <!-- <img src="path/to/image2.jpg" alt="Обширный опыт" class="advantage__image"> -->
                <h3>Обширный опыт</h3>
                <p>Наша значительная экспертиза и налаженные связи с ведущими финансовыми регуляторами и международными юридическими компаниями позволяют нам эффективно осуществлять возврат средств как в стране, так и за ее пределами.</p>
            </div>
            <div class="advantage__card">
                <!-- <img src="path/to/image3.jpg" alt="Профессионализм" class="advantage__image"> -->
                <h3>Профессионализм</h3>
                <p>Наша команда состоит из высококвалифицированных специалистов с богатым опытом работы в области финансов и права. Мы гарантируем качественное выполнение всех обязательств и индивидуальный подход к каждому клиенту.</p>
            </div>
            <div class="advantage__card">
                <!-- <img src="path/to/image4.jpg" alt="Поддержка" class="advantage__image"> -->
                <h3>Поддержка</h3>
                <p>Мы обеспечиваем полное сопровождение и поддержку нашим клиентам на протяжении всего срока действия договора и после его завершения. 99% наших клиентов становятся хорошими друзьями нашей команды!</p>
            </div>
            <div class="advantage__card">
                <!-- <img src="path/to/image5.jpg" alt="Отсутствие предоплаты" class="advantage__image"> -->
                <h3>Отсутствие предоплаты</h3>
                <p>Мы предоставляем всестороннюю поддержку нашим клиентам как в период действия договора, так и после его окончания. 99% наших клиентов становятся близкими друзьями нашей команды!</p>
            </div>
            <div class="advantage__card">
                <!-- <img src="path/to/image6.jpg" alt="Собственная база брокеров" class="advantage__image"> -->
                <h3>Собственная база брокеров</h3>
                <p>За многие годы мы приложили значительные усилия для возврата средств клиентов, пострадавших от недобросовестных брокеров. Теперь вы можете проверить надежность своего брокера или сообщить нам о нём.</p>
            </div>
        </div>
    </section>
    </template>
    
    <script>
    export default {
        name: 'AdvantagesComponent',
    }
    </script>
    
    <style scoped>
    .advantages {
        background-color: var(--text-color); /* Світлий фон для секції */
        padding: 2rem; /* Внутрішні відступи */
        text-align: center; /* Центрування заголовка */
    }
    
    .advantages h2 {
        color: white;
        font-size: 2.5rem; /* Розмір заголовка */
        margin-bottom: 2rem; /* Відступи під заголовком */
    }
    
    .advantages__container {
        display: flex;
        flex-wrap: wrap; /* Дозволити елементам переноситись на новий ряд */
        justify-content: center; /* Центрування елементів */
    }
    
    .advantage__card {
        background-color: white; /* Білий фон для картки */
        border-radius: 10px; /* Закруглені кути */
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Тінь для картки */
        margin: 1rem; /* Відступи між картками */
        padding: 1.5rem; /* Внутрішні відступи */
        flex: 1 1 calc(33.33% - 2rem); /* Встановлення ширини для карток на великих екранах */
        max-width: 300px; /* Максимальна ширина картки */
        transition: transform 0.2s; /* Ефект переходу при наведенні */
    }
    
    .advantage__card:hover {
        transform: translateY(-5px); /* Підйом картки при наведенні */
    }
    
    .advantage__image {
        width: 100%; /* Ширина зображення */
        height: auto; /* Автоматичне співвідношення сторін */
        border-radius: 10px; /* Закруглені кути для зображення */
        margin-bottom: 1rem; /* Відступ між зображенням та текстом */
    }
    
    h3 {
        font-size: 1.5rem; /* Розмір заголовка картки */
        margin: 0.5rem 0; /* Відступи навколо заголовка картки */
    }
    
    p {
        font-size: 1rem; /* Розмір опису */
        color: #666666; /* Колір тексту для опису */
    }
    
    /* Адаптивні стилі для мобільних пристроїв */
    @media (max-width: 768px) {
        .advantages__container {
            gap:20px;
        }
        .advantage__card {
            flex: 1 1 100%; /* Картки по одному в ряд для мобільних пристроїв */
            margin: 0; /* Відсутність відступів між картками */
            max-width: 350px;
        }
    
        .advantages {
            padding: 1rem; /* Зменшити відступи для мобільної версії */
        }
        ._container {
            padding: 0;
        }
        .advantage__card {
            max-width: 100%;
        }
    }
    
    </style>
    