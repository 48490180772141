<template>
  <footer>
    <div class="footer-content">
      <div class="logo">
        <router-link to="/">
          <img :src="Logo" :alt="Logo" class="logo" />
        </router-link>
      </div>
      <ul class="footer-links">
        <li><a href="#how-we-work">Как мы рыботаем</a></li>
        <li><a href="#process">Процесс возврата</a></li>
        <li><a href="#type_of_scams">Типы проблем</a></li>
        <li><a href="#advantages">Наши преимущества</a></li>
        <li><a href="#team">Наша команда</a></li>
        <li><a href="#reviews">Отзывы</a></li>
        <li><a href="#contact_us">Контакты</a></li>
      </ul>
      <p>© 2021 ABLegal. Все права защищены.</p>
   
    </div>
  </footer>
</template>

<script>
import Logo from "@/assets/img/logo2.png";

export default {
  name: "FooterComponent",
  data() {
    return {
      Logo: Logo,
    };
  },
};
</script>

<style scoped>
footer {
  background-color: var(--text-color); /* Використовуйте ваш фон */
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
    list-style: none;
    padding: 0;
    margin: 15px 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.footer-links li {
  margin: 0 10px; /* Відстань між пунктами */
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px;

}

.footer-links a:hover {
  background: var(--accent-color);
  color: var(--text-color);
  border-radius: 50px;
}

.social-links {
  margin-top: 1rem; /* Відстань між списком і соціальними посиланнями */
}

.social-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.social-links a:hover {
  color: var(--accent-color); /* Акцентний колір при наведенні */
}

.logo {
  width: 200px;
  height: auto;
  background-color: white;
  border-radius: 30px;
}
</style>
