<template>
    <section  id="how-we-work" class="first-section _container">
        <div class="header-content">
            <h2>Как мы работаем</h2>
        <p>
          Выбирая ABLegal Group, вы доверяете нам весь процесс возврата средств — от первой консультации до окончательного возмещения. Мы тщательно анализируем каждое дело и разрабатываем индивидуальную стратегию для максимально эффективного возврата ваших инвестиций.
        </p>
        </div>
        <div class="block-content">
          <div class="up_content_block">
            <div class="img_side">
              <img src="@/assets/img/rev1.png" alt="rev1">
            </div>
            <div class="text_side">
              <p>  Сотрудничая с <strong> ABLegal Group</strong>, вы получаете надежного партнера, который защищает ваши интересы. Наша главная цель — вернуть ваши средства и восстановить справедливость. Мы специализируемся на международном коммерческом арбитраже, международном уголовном праве, международном коммерческом праве, международном частном праве, международном банковском праве и исполнении решений международных судов в других юрисдикциях.</p>
            </div>
          </div>
          <div class="middle_block">
            <div class="procces_card">
              <div class="card_img">
                <img src="@/assets/img/1.png" alt="Анализ">
              </div>
              <div class="card_text">
                <p>Детальное изучение вашего случая для определения всех возможных путей возврата средств.</p>
              </div>
            </div>
            <div class="procces_card">
              <div class="card_img">
                <img src="@/assets/img/2.png" alt="Переговоры">
              </div>
              <div class="card_text">
                <p>Профессиональные переговоры с банками и брокерскими компаниями, обеспечивая возврат ваших средств.</p>
              </div>
            </div>
            <div class="procces_card">
              <div class="card_img">
                <img src="@/assets/img/3.png" alt="Конфиденциальность">
              </div>
              <div class="card_text">
                <p>Полная конфиденциальность и внимательное обращение с вашей личной информацией на всех этапах.</p>
              </div>
            </div>
          </div>
          <div class="dawn_content_block">
            <div class="text_side">
              <p>Наша деятельность охватывает весь мир, и мы активно применяем передовые разработки в области криптографии и информационных технологий, что позволяет нам отслеживать и возвращать средства владельцам криптовалют. Мы стремимся обеспечить законный возврат средств их законным владельцам.</p>
            </div>
            <div class="img_side">
              <img src="@/assets/img/rev2.png" alt="rev2">
            </div>
          </div>
          
        </div>

    </section>
  </template>
  
  <script>
  export default {
    name: 'HowWeWorkComponent',
  };
  </script>
  
<style scoped>
  .first-section {
    padding: 2rem;
  }
  .header-content {
    margin: 25px 0;
    text-align: center;
  }
  .header-content h2{
    font-size: 2.1rem;
    margin: 5px 0;
  }
  .block-content {
    display: flex;
    flex-direction: column;
    
  }
  .up_content_block, .dawn_content_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dawn_content_block img, 
  .up_content_block img{
    max-width: 600px;
  }
  .up_content_block p,
  .dawn_content_block p {
    font-size: 1.10rem;
    font-weight: 450;
  }
  .middle_block {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 20px 0;
  }
  .procces_card {
    max-width: 32%;
    display: flex;
    align-items: center;
    background: rgb(241, 251, 255);
    border-radius: 20px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out; /* Анімація на всі зміни */
    padding: 10px;
}

.procces_card:hover {
    border-color: var(--accent-color); /* Бордер при наведенні */
    transform: translateY(-10px); /* Невеликий ефект підняття */
}
  .card_img img{
    width: 150px;
  }
  @media (max-width: 1100px) {
  .dawn_content_block img, 
  .up_content_block img {
      width: 500px;
}
  .card_img img{
    width: 130px;
  }
  .card_text p {
    font-size: 0.9rem;
  }
}
@media (max-width: 950px) {}
.card_text p {
    font-size: 0.8rem;
  }
  .dawn_content_block img, 
  .up_content_block img {
      width: 450px;
}
.up_content_block p,
  .dawn_content_block p {
    font-size: 1rem;
  }
  @media (max-width: 900px) {
    .card_text p {
      font-size: 0.6rem;
  }
  .card_img img{
    width: 100px;
  }
  }
  @media (max-width: 768px) {
    .up_content_block,
    .dawn_content_block {
      flex-direction: column;
    }
    .dawn_content_block img, 
    .up_content_block img {
        width: 350px;
    }
    .middle_block{
      flex-direction: column;
      gap: 5px;
    }
    .procces_card {
      max-width: 100%;
      gap: 5px;
      
    }
  }
</style>
  