<template>
  <section id="team" class="types-of-scams scams back">
    <div class="container">
      <div class="_container">
        <div class="section-title">
          <h2>Наша команда</h2>
        </div>
        <carousel
          class="carousel scams-container"
          :items-to-show="3"
          :items-to-scroll="1"
          :breakpoints="breakpoints"
          navigation
        >
          <slide v-for="testimonial in testimonials" :key="testimonial.id">
            <div class="ts-body">
              <div class="ts-comment">
                <div class="photo_name">
                  <img :src="testimonial.icon" alt="Client photo" class="ts-photo" />
                </div>
                <div class="ts-title">{{ testimonial.title }}</div>
                <div class="ts-text">{{ testimonial.description }}</div>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import Viktor from '@/assets/img/victor.jpg';
import Erlan from '@/assets/img/erlan.jpg';
import Alexey from '@/assets/img/alexey.jpg';
import Hans from '@/assets/img/hans.jpg';
import Anna from '@/assets/img/Anna.jpg';

export default {
  name: 'TeamComponent',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      testimonials: [],
      breakpoints: {
        1200: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        992: {
          itemsToShow: 3,
          itemsToScroll: 1,
        },
        768: {
          itemsToShow: 2,
          itemsToScroll: 1,
        },
        576: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
        320: {
          itemsToShow: 1,
          itemsToScroll: 1,
        },
      },
    };
  },
  methods: {
    fetchTestimonials() {
      this.testimonials = [
        { id: 1, title: 'Виктор Гусин', description: 'Адвокат. Сертифицированный международный эксперт в области финансового права.', icon: Viktor },
        { id: 2, title: 'Ерлан Джумабаев', description: 'Адвокат. Эксперт в области международного финансового права.', icon: Erlan },
        { id: 3, title: 'Алексей Шмидт', description: 'Судебный юрист.', icon: Alexey },
        { id: 4, title: 'Ганс Мюллер', description: 'Адвокат. Юрист по уголовным делам, специалист по международному банковскому и налоговому праву.', icon: Hans },
        { id: 5, title: 'Анна Вилюш', description: 'Адвокат.', icon: Anna },
      ];
      console.log('Static testimonials data:', this.testimonials);
    },
  },
  mounted() {
    this.fetchTestimonials();
  },
};
</script>

<style scoped>
.container {
  color: var(--text-color);
}
.vue3-carousel .carousel-nav {
  display: flex;
  justify-content: space-between;
}
.section-title {
  margin-bottom: 40px;
}
.section-title h2 {
  color: var(--text-color);
  font-size: 2.5rem;
  text-align: center;
}
.ts-body {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  height: 100%;
  margin: 0 10px;
  align-items: flex-start;
}
.ts-comment {
  margin-top: 16px;
}
.photo_name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ts-photo {
  width: 300px;
  height: AUTO;
  border-radius: 5%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ts-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 8px;
}
.ts-text {
  font-size: 1rem;
  color: #333;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}
.ts-stars img {
  height: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    margin: 10px 0;
  }
  .ts-title {
    font-size: 1.75rem;
  }
  .ts-text{ 
    font-size: 1.3rem;
    margin-top: 0;
  }
}
</style>
